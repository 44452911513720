import { Controller } from "stimulus"
export default class extends Controller {
  connect() {
    //this.element.textContent = "Hello Stimulus!"
    console.log("Hello, Stimulus!", this.element)
  }
  greet() {
    console.log("inside the hello#greet method")
    console.info('Toggling IoT Entity', this.element)
    var iot_entity_id = this.element.getAttribute('data-entity-id')
    return fetch(`/admin/api/iot/entity/${iot_entity_id}/toggle`).then((response) => {
    })
    // $.ajax({
    //   url: '/admin/iot/toggle_entity',
    //   type: 'get'
    // })
  }
  auto() {
    console.log("inside the hello#auto method")
    $.ajax({
      url: '/admin/iot/automation_run',
      type: 'get'
    })
  }
}